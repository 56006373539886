/* eslint-disable @typescript-eslint/no-unused-vars */
import { Title } from 'components/Title'
import { useTranslation } from 'react-i18next'
import { Row } from 'components/Grid/Row'
import { PageFilters } from './components/PageFilters'
import { useContext, useEffect, useState } from 'react'
import { useTokenUuid } from 'hooks/request'
import { toQueryParams } from 'api/utils'
import {
  useMergePoints,
  useDemandForecatParams
} from './hooks/useDemandForecast'
import { BrandContext } from 'contexts/BrandProvider'
import { Spinner } from 'components/Spinner'
import { ReactComponent as NoResults } from 'assets/icons/noResults.svg'
import { NoData } from 'components/NoData'
import { Card } from 'components/Card'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from 'components/ErrorFallback'
import { DemandForecastTable } from './components/DemandForecastTable/DemandForecastTable'
import { Button } from 'components/Button'
import { MultiInputChart } from 'components/Graphs/MultiInputChart/MultiInputChart'
import {
  getDemandByModelFourMonths,
  getDemandByMTFFourMonths,
  postDemandForecastMyMtfFourMonths,
  postDemandForecastMyModelFourMonths
} from 'api/pricing/demand'
import { useLocation } from 'react-router-dom'

const DemandForecast = () => {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [isEdited, setIsEdited] = useState<boolean>(false)
  const [indexEditRow, setIndexEditRow] = useState<number | undefined>(
    undefined
  )
  const [data, setData] = useState<any>([])
  const [dataGraph, setDataGraph] = useState<any>([])

  const [dataPlain, setDataPlain] = useState<any>([])
  const [dataGraphPlain, setDataGraphPlain] = useState<any>([])

  const [isDataPlainInitialized, setIsDataPlainInitialized] = useState(false)

  const [mtfSelected, setMtfSelected] = useState<any>({})
  const [error, setError] = useState(false)

  const { brand } = useContext(BrandContext)

  const [updatedValue, setUpdatedvalue] = useState<any>()
  const [resetKey, setResetKey] = useState(0)

  const uuid = useTokenUuid()

  const location = useLocation()
  const paramsLocation = location.state || {}
  const { model } = paramsLocation

  const params = useDemandForecatParams()

  let queryParams = toQueryParams({
    brand: brand,
    model: params.model
  })

  const updateInputValue = (val) => setUpdatedvalue(val)

  // By Model
  const fetchDemandDataModel = async () => {
    setIsLoading(true)
    const resp: any = await getDemandByModelFourMonths(uuid, queryParams)
    if (resp !== 500 && resp !== 204 && resp !== 404) {
      const result = useMergePoints(
        resp.cars_in_stock_points,
        resp.confs_points,
        resp.corrected_discount_points,
        resp.total_budget_points,
        resp.traffic_shw_points,
        resp.orders_points
      )
      setDataGraph(result)
      setData(resp)

      if (!isDataPlainInitialized) {
        setDataPlain({ ...resp })
        setDataGraphPlain([...result])
        setIsDataPlainInitialized(true)
      }
    } else setError(true)
    setIsLoading(false)
  }

  // By MTF
  const fetchDemandDataMTF = async () => {
    setIsLoading(true)
    queryParams = `model_variant=${params.mtf.model_variant}&trim=${params.mtf.trim}&fuel=${params.mtf.fuel}&${queryParams}`
    const resp: any = await getDemandByMTFFourMonths(uuid, queryParams)

    if (resp !== 500 && resp !== 204 && resp !== 404) {
      const result = useMergePoints(
        resp.cars_in_stock_points,
        resp.confs_points,
        resp.corrected_discount_points,
        resp.total_budget_points,
        resp.traffic_shw_points,
        resp.orders_points
      )
      setDataGraph(result)
      setData(resp)

      if (!isDataPlainInitialized) {
        setDataPlain({ ...resp })
        setDataGraphPlain([...result])
        setIsDataPlainInitialized(true)
      }
    } else setError(true)
    setIsLoading(false)
  }

  const editMode = () => {
    setIndexEditRow(
      mtfSelected.model_variant
        ? data?.current_month_mtfs?.findIndex((obj) => {
            return (
              obj.model === mtfSelected.model &&
              obj.model_variant === mtfSelected.model_variant &&
              obj.trim === mtfSelected.trim &&
              obj.fuel === mtfSelected.fuel
            )
          })
        : data?.current_month_mtfs?.length - 1
    )

    setIsEditMode(true)
    setIsEdited(true)
  }

  const resetData = () => {
    setResetKey((prev) => prev + 1)
    if (typeof dataPlain === 'object' && dataPlain !== null) {
      setData({
        ...dataPlain,
        current_month_mtfs: [...dataPlain.current_month_mtfs]
      })
    } else {
      setData({})
    }

    if (Array.isArray(dataGraphPlain)) {
      setDataGraph([...dataGraphPlain])
    } else {
      setDataGraph([])
    }
  }
  const updateData = async () => {
    setIsLoading(true)

    if (mtfSelected.model_variant) {
      const body = {
        forecasting_inputs_list: [
          {
            mtf: {
              brand: brand,
              model_variant: mtfSelected.model_variant,
              model: mtfSelected.model,
              trim: mtfSelected.trim,
              fuel: mtfSelected.fuel
            },
            inputs: {
              corrected_discount: parseFloat(updatedValue.corrected_discount),
              traffic_shw: parseFloat(updatedValue.traffic_shw),
              cars_in_stock: parseFloat(updatedValue.cars_in_stock),
              confs: parseFloat(updatedValue.confs),
              total_budget: parseFloat(updatedValue.total_budget)
            }
          }
        ]
      }
      const res: any = await postDemandForecastMyMtfFourMonths(uuid, body)

      if (res !== 500 && res !== 204 && res !== 404) {
        // let lastObject = dataGraph[dataGraph.length - 1]
        // lastObject.orders_points =
        //   res.forecasting_output[0].output.forecasted_orders
        // lastObject.cars_in_stock_points =
        //   res?.forecasting_inputs_list[0]?.inputs?.cars_in_stock
        // lastObject.confs_points = res?.forecasting_inputs_list[0]?.inputs?.confs
        // lastObject.corrected_discount_points =
        //   res?.forecasting_inputs_list[0]?.inputs?.corrected_discount
        // lastObject.total_budget_points =
        //   res?.forecasting_inputs_list[0]?.inputs?.total_budget
        // lastObject.traffic_shw_points =
        //   res?.forecasting_inputs_list[0]?.inputs?.traffic_shw

        if (indexEditRow !== undefined) {
          let newObjectUpdated = {
            ...data.current_month_mtfs[indexEditRow],
            ...updatedValue
          }
          data.current_month_mtfs[indexEditRow] = newObjectUpdated

          setData(data)
        }
      }
    } else {
      const body = {
        forecasting_inputs_for_model: {
          brand: brand,
          model: params.model,
          corrected_discount: updatedValue.corrected_discount,
          traffic_shw: updatedValue.traffic_shw,
          cars_in_stock: updatedValue.cars_in_stock,
          confs: updatedValue.confs,
          total_budget: updatedValue.total_budget
        }
      }
      const res: any = await postDemandForecastMyModelFourMonths(uuid, body)
      setData(res)
      // dataGraph[dataGraph.length - 1].cars_in_stock_points =
      //   res.cars_in_stock_forecast_point.value
      // dataGraph[dataGraph.length - 1].confs_points =
      //   res.confs_forecast_point.value
      // dataGraph[dataGraph.length - 1].corrected_discount_points =
      //   res.corrected_discount_forecast_point.value
      // dataGraph[dataGraph.length - 1].orders_points =
      //   res.orders_forecast_point.value
      // dataGraph[dataGraph.length - 1].total_budget_points =
      //   res.total_budget_forecast_point.value
      // dataGraph[dataGraph.length - 1].traffic_shw_points =
      //   res.traffic_shw_forecast_point.value
    }
    setDataGraph(dataGraph)
    setIsLoading(false)
    setIsEditMode(false)
  }

  useEffect(() => {
    setError(false)
    setIsEditMode(false)
    if (params.model && params.mtf?.value !== 'View all MTFs') {
      uuid && fetchDemandDataMTF()
    }
    if (params.model && params.mtf?.value === 'View all MTFs') {
      uuid && fetchDemandDataModel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid, queryParams, params.mtf])

  useEffect(() => {
    setData([])
    setIsEditMode(false)
    setIsDataPlainInitialized(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid, brand])

  if (data === undefined) {
    return <NoData feed="404" />
  }

  return (
    <>
      <Title
        label={t('pricing.demandForecast.title')}
        data-testid="dashboard-view"
      />
      <Row justify="space-between" align="center">
        <PageFilters
          handleFilters={(val) => setMtfSelected(val)}
          selectedModel={model ?? null}
        />
      </Row>

      {isLoading && <Spinner />}

      {!isLoading && !error && dataGraph.length !== 0 && (
        <MultiInputChart data={dataGraph} key={resetKey} />
      )}
      {!isLoading && !error && data.length !== 0 && (
        <Card
          headerTitle="Summary"
          gridType="full"
          headerComponent={
            isEditMode ? (
              <div style={{ gap: '1rem', display: 'flex' }}>
                <Button label="Cancel" onClick={() => setIsEditMode(false)} />
                <Button label="Save" look="filled" onClick={updateData} />
              </div>
            ) : (
              <div style={{ gap: '1rem', display: 'flex' }}>
                {/* {isEdited && <Button label="Reset" onClick={resetData} />} */}
                <Button label="Edit" look="filled" onClick={editMode} />
              </div>
            )
          }
        >
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <div>
              <DemandForecastTable
                key={resetKey}
                data={data.current_month_mtfs ? data.current_month_mtfs : data}
                isEditMode={isEditMode}
                indexEditRow={indexEditRow}
                handleUpdateInputValue={(val) => updateInputValue(val)}
              />
            </div>
          </ErrorBoundary>
        </Card>
      )}

      {!isLoading && !error && data.length === 0 && (
        <div style={{ textAlign: 'center' }}>
          <NoResults />
          <h3 style={{ fontWeight: 400 }}>Select some input from filters...</h3>
        </div>
      )}

      {!isLoading && error && (
        <div style={{ textAlign: 'center' }}>
          <NoResults />
          <h3 style={{ fontWeight: 400 }}>
            No data with this filters. You can change them to find results
          </h3>
        </div>
      )}
    </>
  )
}

export { DemandForecast }
